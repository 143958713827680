import { Application } from './styled';
import Main from '../../Pages/Main';
import '../../index.css';

function App() {
  return (
    <Application>
        <Main/>
    </Application>
  );
}

export default App;