import React from 'react';
import { TableWrapper, THead, Tr, TBody, Td, Th, GreenEllypse, RedEllypse, ButtonsWrapper, DeleteButton, EditButton, StopButton, StartButton } from './styled';

interface RowData {
    id: number;
    isBotOn: string;
    link: string;
    reply_message: string;
    subscribers: number;
    support_link: string;
    buttons: IButtons[]
}

interface IButtons {
    id: number
    name: string,
    link: string,
    bot_id: number
}

interface TableProps {
    data: RowData[];
    isButtonBlocked: boolean;
    setPopupDeleteBot: (arg: number) => void;
    setPopupStartBot: (arg: number) => void;
    setPopupStopBot: (arg: number) => void;
    setEditData: (arg: any) => void;
    setButtonData: (arg: IButtons[]) => void;
    setShowButton: (arg: number) => void;
}


const Table: React.FC<TableProps> = ({ data, isButtonBlocked, setPopupDeleteBot, setPopupStartBot, setPopupStopBot, setEditData, setButtonData, setShowButton }) => {

    console.log(data);
    

    return (
        <TableWrapper>
            <THead>
                <Tr>
                    <Th>ID</Th>
                    <Th>Link</Th>
                    <Th>Reply message</Th>
                    <Th>Support link</Th>
                    <Th>Subscribers</Th>
                    <Th>Status</Th>
                    <Th style={{width: '200px'}}></Th>
                </Tr>
            </THead>
            <TBody>
                {data.map((row, index) => (
                    <Tr key={index}>
                        <Td>{row.id}</Td>
                        <Td><a href={row.link} target="_blank" rel="noopener noreferrer">{row.link}</a></Td>
                        <Td>{row.reply_message}</Td>
                        <Td><a href={row.support_link} target="_blank" rel="noopener noreferrer">{row.support_link}</a></Td>
                        <Td>{row.subscribers}</Td>
                        <Td>{row.isBotOn ? <GreenEllypse/> : <RedEllypse/>}</Td>
                        <Td style={{width: '200px'}}>
                            <ButtonsWrapper>
                                <EditButton onClick={() => setEditData({data: {link: row.link, reply_message: row.reply_message, support_link: row.support_link, subscribers: row.subscribers}, id: row.id})}>Edit bot</EditButton>
                                <EditButton onClick={() => {setShowButton(row.id); setButtonData(row.buttons)}}>Show buttons</EditButton>
                                <DeleteButton onClick={() => setPopupDeleteBot(row.id)} disabled={isButtonBlocked}>Delete</DeleteButton>
                                {row.isBotOn ? <StopButton onClick={() => setPopupStopBot(row.id)} disabled={isButtonBlocked}>Stop</StopButton> :
                                <StartButton onClick={() => setPopupStartBot(row.id)} disabled={isButtonBlocked}>Start</StartButton>}
                            </ButtonsWrapper>
                        </Td>
                    </Tr>
                ))}
            </TBody>
        </TableWrapper>
    );
};

export default Table;