import styled from 'styled-components';

export const Background = styled.div`
    width: 100vw;
    height: 100vh;
    background-color: #87a9d7;
`

// popup start

export const Popup = styled.div`
    position: absolute;
    text-align: center;
    display: flex;
    gap: 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    left: 50%;
    top: 50px;
    transform: translate(-50%);
    width: 200px;
    height: 100px;
    background-color: white;
    border-radius: 8px;
    z-index: 1000;
`

export const PopupText = styled.p`
    margin: 0;
    font-family: 'Roboto Mono', monospace;
    font-weight: 400;
    font-size: 16px;
    color: black;
`

export const ButtonsWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`

export const AccessButton = styled.button`
    width: 80px;
    height: 40px;
    background-color: #00CC00;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
`
export const RejectButton = styled.button`
    width: 80px;
    height: 40px;
    background-color: #FF0000;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
`

//popup end

export const Container = styled.div`
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    height: 60%;
    background-color: #C5FAD3;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    gap: 15px;
` 

export const ContainerHeader = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    height: 80px;
    width: 100%;
    background-color: white;
    border-radius: 8px 8px 0 0;
`

export const HeaderButton = styled.button`
    font-family: 'Roboto Mono', monospace;
    font-weight: 400;
    color: white;
    height: 40px;
    width: 120px;
    font-size: 12px;
    background-color: #035066;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border: none;
    cursor: pointer;
`
export const ContainerList = styled.div`
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    gap: 15px;
    height: 360px;
    width: 90%;
    background-color: white;
    border-radius: 8px;
`

// Modal

export const AddModal = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    width: 800px;
    height: 800px;
    background-color: white;
    border-radius: 8px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
`

export const ModalTitle = styled.h2`
    font-family: 'Roboto Mono', monospace;
    font-size: 20px;
    font-weight: 500;
    color: black;
`

export const Overlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 999;
`;

export const AddInput = styled.input`
    height: 40px;
    width: 600px;
    outline: none;
    border-radius: 4px;
`

export const ModalAddInput = styled.input`
    height: 20px;
    width: 100px;
    border-radius: 4px;
`
export const Label = styled.label`
  display: inline-block;
  padding: 10px 15px;
  background-color: #3498db;
  color: white;
  border-radius: 5px;
  cursor: pointer;
`;

export const ButtonText = styled.p`
    font-family: 'Roboto Mono', monospace;
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    width: 120px;
    color: black;
    word-wrap: break-word;
    white-space: normal;
    font-size: 12px;
`

export const ModalInputWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;
`
export const EditInpurWrapper = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
`

export const ModalNameInput = styled.p`
    font-family: 'Roboto Mono', monospace;
    font-size: 14px;
    width: 120px;
    font-weight: 400;
    margin: 0;
`

export const ButtonsList = styled.div`
    width: 600px;
    max-height: 250px;
    background-color: #C5FAD3;
    border-radius: 8px;
    display: block;
    overflow-y: auto;
`

export const AddButtonsWrapper = styled.div`
    display: flex;
    justify-content: start;
    padding: 10px;
    gap: 20px;
    align-items: center;
    border-bottom: 1px solid black;
`
export const ModalAddButton = styled.button`
    height: 25px;
    width: 100px;
    border-radius: 4px;
`

export const DeleteButton = styled.button`
    width: 60px;
    height: 20px;
    font-size: 12px;
    background-color: #FF0000;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
`

export const CreateButton = styled.button`
    width: 600px;
    height: 60px;
    background-color: #00CC00;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
`

export const HelpButton = styled.button`
    width: 80px;
    height: 30px;
    font-size: 10px;
    background-color: #035066;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
`