import styled from "styled-components"

export const TableWrapper = styled.table`
    width: 100%;
    border-collapse: collapse;
`
export const THead = styled.thead`
 
`
export const TBody = styled.tbody`
    display: block;
    max-height: 300px;
    overflow-y: auto;
`

export const Tr = styled.tr`
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    border-bottom: 1px solid #ddd;
    text-align: left;
    padding: 8px;
`
export const Td = styled.td`
    width: 100px;
    word-wrap: break-word;
    white-space: normal;
    font-size: 12px;
`

export const Th = styled.th`
    width: 100px;
`

export const GreenEllypse = styled.div`
    height:  20px;
    width: 20px;
    border-radius: 50%;
    background-color: #00CC00;
`
export const RedEllypse = styled.div`
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: #FF0000;
`

export const ButtonsWrapper = styled.div`
    display: flex;
    gap: 4px;
`

export const DeleteButton = styled.button`
    width: 60px;
    height: 30px;
    font-size: 10px;
    background-color: #FF0000;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
`

export const EditButton = styled.button`
    width: 80px;
    height: 30px;
    font-size: 10px;
    background-color: #035066;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
`

export const StopButton = styled.button`
    width: 60px;
    height: 30px;
    font-size: 10px;
    background-color: #FF0000;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
`

export const StartButton = styled.button`
    width: 60px;
    height: 30px;
    font-size: 10px;
    background-color: #00CC00;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
`