
import { useState, useRef, useEffect } from "react";
import { AccessButton, AddModal, Background, ButtonsWrapper, Container, ContainerHeader, ContainerList, HeaderButton, Overlay, Popup, PopupText, 
    RejectButton, AddInput, ModalInputWrapper, ModalTitle, ButtonsList, AddButtonsWrapper, ModalAddInput, ModalAddButton, ButtonText, DeleteButton, CreateButton,
    EditInpurWrapper, Label,
    ModalNameInput,
    HelpButton} from "./styled";
import axios from "axios";
import Table from "../../Components/Tables/Table";
import useOnClickOutside from 'use-onclickoutside';
import MDEditor from '@uiw/react-md-editor';
import { commands } from "@uiw/react-md-editor";

interface IButtons {
    id: number;
    name: string,
    link: string,
    bot_id: number
}

interface RowData {
    id: number;
    isBotOn: string;
    link: string;
    reply_message: string;
    subscribers: number;
    support_link: string;
    buttons: IButtons[]
}

interface List {
    name: string,
    link: string
}

interface INewBotData {
    token: string,
    link: string,
    reply_message: string,
    [key: string]: string | number;
}

interface IEditBotData {
    subscribers: number
    link: string,
    reply_message: string,
    [key: string]: string | number;
}

interface IEditBot {
    data: IEditBotData,
    id: number
}

interface IMessageData {
    id: number[],
    client_id?: number[],
    message: string,
    file?: any,
    reg_date?: string
}

const Main = () => {

    const listRef = useRef(null);

    const [list, setList] = useState<RowData[]>();
    const [popupStartBots, setPopupStartBots] = useState(false);
    const [popupStartBot, setPopupStartBot] = useState(0);
    const [popupStopBot, setPopupStopBot] = useState(0);
    const [popupDeleteBot, setPopupDeleteBot] = useState(0);
    const [isOpenAddModal, setIsOpenAddModal] = useState(false);
    const [isOpenSendMessage, setIsOpenSendMessage] = useState(false);
    const [isButtonBlocked, setIsButtonBlocked] = useState(false);
    const [isChecked, setIsChecked] = useState(false);

    const [name, setName] = useState('');
    const [link, setLink] = useState('');
    const [buttons, setButtons] = useState<List[]>([]);
    const [data, setData] = useState<INewBotData>({token: '', link: '', reply_message: ''})
    const [editData, setEditData] = useState<IEditBot>({data: {link: '', reply_message: '', subscribers: 0}, id: 0});
    const [buttonData, setButtonData] = useState<IButtons[]>([]);
    const [isShowButton, setShowButton] = useState<number>(0);
    const [messageData, setMessageData] = useState<IMessageData>({id: [], message: ''});
    const [isMessageError, setIsMessageError] = useState(false);
    const [selectedFile, setSelectedFile] = useState<any>(null);

    const [isCreateError, setIsCreateError] = useState(false);
    const [isEditError, setIsEditError] = useState(false);
    const [isButtonError, setIsButtonError] = useState(false);
    const [isLinkError, setIsLinkError] = useState(false);
    const [isFileError, setIsFileError] = useState(false);

    const [isSendError, setIsSendError] = useState(false);

    const [isShowHelp, setIsShowHelp] = useState(false);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            const userToken = prompt('Enter token');
            if (userToken) {
                localStorage.setItem('token', userToken);
            } else {
                alert('Token wasn\'t enter');
            }
        }
    }, []); 


    const getListBot = async () => {
        try {
            const response = await axios.get('https://tgbots.teracock.com/bot', {headers: {
                Authorization: localStorage.getItem('token')
            }});

            if (isShowButton) {
                const bot = response.data.find((item: any) => item.id === isShowButton);
                const updateButtons = [...bot.buttons];
                setButtonData(updateButtons);
            }
    
            setList(response.data); 
        } catch (error) {
            setIsSendError(true);
            setTimeout(() => {
                setIsSendError(false);
            }, 5000)
        }
    }

    const closePopup = () => {
        setPopupStartBots(false);
        setPopupStartBot(0);
        setPopupStopBot(0);
        setPopupDeleteBot(0);
        setIsCreateError(false);
        setIsSendError(false);
        setIsEditError(false);
        setIsButtonError(false);
        setIsButtonBlocked(false);
        setIsLinkError(false);
        setIsMessageError(false);
        setIsShowHelp(false);
    }

    const startBots = async () => {
        try {
            setIsButtonBlocked(true);
            await axios.put('https://tgbots.teracock.com/bot/start', null, {headers: {
                Authorization: localStorage.getItem('token')
            }});
            await getListBot();
            closePopup();
            setIsButtonBlocked(false);
        } catch (error) {
            setIsSendError(true);
            setTimeout(() => {
                setIsSendError(false);
            }, 5000)
            setIsButtonBlocked(false);
        }
    }

    const startBot = async () => {
        try {
            setIsButtonBlocked(true);
            await axios.put(`https://tgbots.teracock.com/bot/start/${popupStartBot}`, null, {headers: {
                Authorization: localStorage.getItem('token')
            }});
            getListBot();
            closePopup();
            setIsButtonBlocked(false);  
        } catch (error) {
            setIsSendError(true);
            setTimeout(() => {
                setIsSendError(false);
            }, 5000)
            setIsButtonBlocked(false);
        }
    }

    const stopBot = async () => {
        try {
            setIsButtonBlocked(true);
            await axios.put(`https://tgbots.teracock.com/bot/stop/${popupStopBot}`, null, {headers: {
                Authorization: localStorage.getItem('token')
            }});
            getListBot();
            closePopup();
            setIsButtonBlocked(false);
        } catch (error) {
            setIsSendError(true);
            setTimeout(() => {
                setIsSendError(false);
            }, 5000)
            setIsButtonBlocked(false);
        }
    }

    useOnClickOutside(listRef, () => {
        setIsOpenAddModal(false);
        setEditData({data: {token: '', link: '', reply_message: '', subscribers: 0}, id: 0});
        setShowButton(0);
        setButtonData([]);
        setIsOpenSendMessage(false);
        setIsShowHelp(false);
    });

    const changeInputData = (column: string, value: string) => {
        const updateData = {...data};
        updateData[column] = value;
        setData(updateData);
    }

    const changeEditInputData = (column: string, value: string) => {
        const updateData = {...editData};
        updateData.data[column] = value;
        setEditData(updateData);
    }

    const addButton = () => {
        const urlPattern = /^(https):\/\/[^ "]+\.[^ "]+$/;

        if(name.trim() && link.trim() && urlPattern.test(link)) {
            const updateButtons = [...buttons, {name: name, link: link}];
            setButtons(updateButtons);
            setName('');
            setLink('');
        } else {
            setIsLinkError(true);
            setIsButtonError(true);

        }
    }

    const deleteButton = (index: number) => {
        const updatedButtons = [...buttons];
        updatedButtons.splice(index, 1);
        setButtons(updatedButtons);
    }

    const createNewBot = async () => {
        try {
            if (!data.link.trim() || !data.reply_message.trim() || !data.token.trim()) {
                setIsCreateError(true);
            } else {
                setIsButtonBlocked(true);
                const newBot = {
                    data: {...data},
                    buttons: buttons
                }
                
                await axios.post('https://tgbots.teracock.com/bot', newBot, {headers: {
                    Authorization: localStorage.getItem('token')
                }});
                await getListBot(); 
                setIsOpenAddModal(false);
                setIsButtonBlocked(false);
            }
        } catch (error) {
            setIsSendError(true);
            setTimeout(() => {
                setIsSendError(false);
            }, 5000)
            setIsButtonBlocked(false);
        }
    }

    const editBot = async () => {
        try {
            if (!editData.data.link.trim() || !editData.data.reply_message.trim()) {
                setIsEditError(true);
            } else {
                setIsButtonBlocked(true);
                const editBot = {
                    id: editData.id,
                    data: {...editData.data}
                }
                await axios.put('https://tgbots.teracock.com/bot', editBot, {headers: {
                    Authorization: localStorage.getItem('token')
                }});
                await getListBot();
                setEditData({data: {token: '', link: '', reply_message: '', subscribers: 0}, id: 0});
                setIsButtonBlocked(false);
            }
        } catch (error) {
            setIsSendError(true);
            setTimeout(() => {
                setIsSendError(false);
            }, 5000)
            setIsButtonBlocked(false);
        }
    }

    const deleteBot = async () => {
        try {
            setIsButtonBlocked(true);
            await axios.delete('https://tgbots.teracock.com/bot', {data: {id: popupDeleteBot}, headers: {
                Authorization: localStorage.getItem('token')
            }})
            await getListBot();
            closePopup();
        } catch (error) {
            setIsSendError(true);
            setTimeout(() => {
                setIsSendError(false);
            }, 5000)
            setIsButtonBlocked(false);
        }
    }

    const sendNewButton = async () => {
        try {
            const urlPattern = /^(https):\/\/[^ "]+\.[^ "]+$/;

            if(name.trim() && link.trim() && urlPattern.test(link)) {
                setIsButtonBlocked(true);
                const newButton = {
                    id: isShowButton,
                    buttons: [{name: name, link: link}]
                }
                await axios.post('https://tgbots.teracock.com/button', newButton, {headers: {
                    Authorization: localStorage.getItem('token')
                }});
                await getListBot(); 
                setIsButtonBlocked(false);
                setIsLinkError(false);
                setIsButtonError(false);
            } else {
                setIsLinkError(true);
                setIsButtonError(true);
            }
        } catch (error) {
            setIsSendError(true);
            setTimeout(() => {
                setIsSendError(false);
            }, 5000)
            setIsButtonBlocked(false);
        }
    }
    const sendDeleteButton = async (index: number) => {
        setIsButtonBlocked(true);
        const deleteButton = {
            id: buttonData[index].bot_id,
            buttons: [buttonData[index].id]
        }
        await axios.delete('https://tgbots.teracock.com/button', {data: deleteButton, headers: {
            Authorization: localStorage.getItem('token')
        }});
        await getListBot(); 
        setIsButtonBlocked(false);
    }

    const handleCheckboxChange = (event: any) => {
        const updateMessage = {...messageData};
        if (event.target.checked && list) {
            updateMessage.id = list.map((item) => item.id);
        } else {
            updateMessage.id = [];
        }
        setMessageData(updateMessage);
        setIsChecked(event.target.checked);
    };

    const changeMessage = (value: string | undefined) => {
        setIsMessageError(false);
        const updateMessage = {...messageData};
        updateMessage.message = value ? value : '';
        setMessageData(updateMessage);
    }

    const changeDate= (event: any) => {
        console.log('WTF');
        console.log(event.target.value);
        
        const updateMessage = {...messageData};
        updateMessage.reg_date = event.target.value;
        setMessageData(updateMessage);
    }

    const deleteBotFromMessageList = (id: number) => {
        const updateMessage = {...messageData};
        const index = updateMessage.id.findIndex((item) => item === id);
        updateMessage.id.splice(index, 1);
        setIsChecked(false);
        setMessageData(updateMessage);
    }

    const addBotToMessageList = (id: number) => {
        const updateMessage = {...messageData};
        updateMessage.id.push(id);
        setMessageData(updateMessage);
    }

    const sendMessage = async () => {
        try {
            if (!messageData.message) {
                setIsMessageError(true);
                setIsButtonBlocked(false);
            } else {
                setIsButtonBlocked(true);
                const data = new FormData();
                data.append('file', selectedFile);
                
                if (messageData) {
                    Object.keys(messageData).forEach(key => {
                        if (typeof key === 'string') {
                            console.log(key);
                            
                            data.append(key, messageData[key as keyof IMessageData]);
                        }
                    });
                }
                
                await axios.post('https://tgbots.teracock.com/bot/message', data, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: localStorage.getItem('token')
                    }
                });
                setIsOpenSendMessage(false);
                setIsButtonBlocked(false);
                setSelectedFile(null);
            }
        } catch (error) {
            console.log(error);
            
            setIsButtonBlocked(false);
        }
    }

    const handleFileChange = (event: any) => {
        
        if (event.target.files[0]) {
            const type = event.target.files[0].type;
        
            if (type === 'image/png' || type === 'image/jpg' || type === 'image/jpeg' || type === 'image/gif') {
                setSelectedFile(event.target.files[0]);
                setIsFileError(false);
            } else {
                setIsFileError(true);
                setTimeout(() => {
                    setIsFileError(false);
                }, 5000)
            }
        }
    };

    return(
        <Background>
            {isSendError && !isOpenAddModal && !editData.id && !isOpenSendMessage && <p style={{position: 'absolute', left: '50%', transform: 'translate(-50%)', top: '100px', margin: 0, color: 'red'}}>Something went wrong</p>}
            {popupStartBots && 
            <Popup>
                <PopupText>Do you really want to start all bots?</PopupText>
                <ButtonsWrapper>
                    <AccessButton disabled={isButtonBlocked} onClick={startBots}>Access</AccessButton>
                    <RejectButton onClick={closePopup}>Reject</RejectButton>
                </ButtonsWrapper>
            </Popup>
            }
            {popupDeleteBot !== 0 && 
            <Popup>
                <PopupText>Do you really want to delete bot?</PopupText>
                <ButtonsWrapper>
                    <AccessButton disabled={isButtonBlocked} onClick={deleteBot}>Access</AccessButton>
                    <RejectButton onClick={closePopup}>Reject</RejectButton>
                </ButtonsWrapper>
            </Popup>
            }
            {popupStopBot !== 0 && 
            <Popup>
                <PopupText>Do you really want to stop bot?</PopupText>
                <ButtonsWrapper>
                    <AccessButton disabled={isButtonBlocked} onClick={stopBot}>Access</AccessButton>
                    <RejectButton onClick={closePopup}>Reject</RejectButton>
                </ButtonsWrapper>
            </Popup>
            }
            {popupStartBot !== 0 && 
            <Popup>
                <PopupText>Do you really want to start bot?</PopupText>
                <ButtonsWrapper>
                    <AccessButton disabled={isButtonBlocked} onClick={startBot}>Access</AccessButton>
                    <RejectButton onClick={closePopup}>Reject</RejectButton>
                </ButtonsWrapper>
            </Popup>
            }
            {isOpenAddModal &&
                <Overlay>
                    <AddModal ref={listRef}>
                        {isSendError && <p style={{margin: 0, color: 'red'}}>Something went wrong</p>}
                        <ModalTitle>Create new bot</ModalTitle>
                        <ModalInputWrapper>
                            <AddInput style={{border: isCreateError && !data.token.trim() ? '1px solid #FF0000' : '1px solid black'}} value={data.token} onChange={(e) => changeInputData('token', e.target.value)} placeholder="Token"/>
                            <AddInput style={{border: isCreateError && !data.link.trim() ? '1px solid #FF0000' : '1px solid black'}} value={data.link} onChange={(e) => changeInputData('link', e.target.value)} placeholder="Link"></AddInput>
                            <AddInput style={{border: isCreateError && !data.reply_message.trim() ? '1px solid #FF0000' : '1px solid black'}} value={data.reply_message} onChange={(e) => changeInputData('reply_message', e.target.value)} placeholder="Reply message"></AddInput>
                            <AddInput style={{border: '1px solid black'}} value={data.support_link} onChange={(e) => changeInputData('support_link', e.target.value)} placeholder="Support link"></AddInput>
                        </ModalInputWrapper>
                        <ButtonsList>
                            <AddButtonsWrapper style={{height: '20px'}}>
                                <ButtonText>Name</ButtonText>
                                <ButtonText>Link</ButtonText>
                            </AddButtonsWrapper>
                            {buttons.map((item, index) => {
                                return(
                                    <AddButtonsWrapper>
                                        <ButtonText>{buttons[index].name}</ButtonText>
                                        <ButtonText>{buttons[index].link}</ButtonText>
                                        <DeleteButton onClick={() => deleteButton(index)}>Delete</DeleteButton>
                                    </AddButtonsWrapper>
                                )
                            })}
                            <AddButtonsWrapper style={{border: 'none'}}>
                                <ModalAddInput style={{height: '40px', width: '230px', border: isButtonError && !name.trim() ? '1px solid #FF0000' : '1px solid black'}} value={name} onChange={(e) => setName(e.target.value)} placeholder="Name"/>
                                <ModalAddInput style={{height: '40px', width: '230px', border: isButtonError && isLinkError ? '1px solid #FF0000' : '1px solid black'}} value={link} onChange={(e) => setLink(e.target.value)} placeholder="Link"/>
                                <ModalAddButton onClick={addButton}>Add button</ModalAddButton>
                            </AddButtonsWrapper>
                        </ButtonsList>
                        <CreateButton disabled={isButtonBlocked} onClick={createNewBot}>Create</CreateButton>
                    </AddModal>
                </Overlay>
            }
            {isOpenSendMessage &&
                <Overlay>
                    <AddModal ref={listRef}>
                        {isFileError && <p style={{margin: 0, color: '#FF0000'}}>Error type file</p>}
                        <ModalTitle>Send message</ModalTitle>
                        <ModalInputWrapper>
                            {isMessageError && <p style={{color: '#FF0000'}}>Please add message</p>}
                            <MDEditor
                                value={messageData.message}
                                onChange={(value) => changeMessage(value)}
                                commands={[
                                    commands.bold,
                                    commands.italic,
                                    commands.strikethrough,
                                    commands.link
                                  ]}
                            />
                            <HelpButton onClick={() => setIsShowHelp(true)}>Help</HelpButton>
                            <AddInput type="date" style={{width: '480px'}} value={messageData.reg_date} onChange={changeDate}></AddInput>
                            <div style={{display: 'flex', gap: '20px', alignItems: 'center'}}>
                                <Label htmlFor="fileInput">{selectedFile ? selectedFile.name : "Choose jpg, jpeg, png, gif"}</Label>
                                {selectedFile && <DeleteButton onClick={() => setSelectedFile(null)}>Remove</DeleteButton>}
                            </div>
                            <AddInput type="file" id="fileInput" accept="image/*" style={{display: 'none'}} onChange={handleFileChange}></AddInput>
                        </ModalInputWrapper>
                        <ButtonsList style={{width: '480px'}}>
                            <AddButtonsWrapper style={{height: '20px'}}>
                                <ButtonText>Id</ButtonText>
                                <ButtonText>Link</ButtonText>
                                <label style={{display: 'flex', gap: '4px'}}>
                                    <input
                                        type="checkbox"
                                        checked={isChecked}
                                        onChange={handleCheckboxChange}
                                    />
                                    <p style={{margin: 0, fontSize: '14px'}}>Choose all</p>
                                </label>
                            </AddButtonsWrapper>
                            {list?.map((item, index) => {
                                if (item.isBotOn) {
                                    return(
                                        <AddButtonsWrapper>
                                            <ButtonText>{list[index].id}</ButtonText>
                                            <ButtonText>{list[index].link}</ButtonText>
                                            {messageData.id.find((item) => item === list[index].id) ? <DeleteButton onClick={() => deleteBotFromMessageList(list[index].id)}>Remove</DeleteButton> : 
                                            <DeleteButton style={{backgroundColor: '#008500'}} onClick={() => addBotToMessageList(list[index].id)}>Choose</DeleteButton>}
                                        </AddButtonsWrapper>
                                    )
                                }
                            })}
                        </ButtonsList>
                        <CreateButton style={{width: '480px'}} disabled={isButtonBlocked} onClick={sendMessage}>Send</CreateButton>
                        {isShowHelp && 
                        <Popup style={{border: '1px solid black', width: '800px', height: '700px'}}>
                            <PopupText>Пример ссылки: https://www.test.com</PopupText>
                            <br></br>
                            <PopupText>Паттерны подставляются в текст, ссылки и кнопки внутри &#123;&#125; - пример: https://www.test.com?sub=&#123;start_param&#125;. 
                                При подстановке несуществующего паттерна в ссылку, или 
                                если значение для паттерна отсутствует - будет подставлен null. При подстановке несуществующего паттерна в обычный текст или отсутствии значения
                                паттерн автоматически удалится из сообщения. 
                            </PopupText>
                            <br></br>
                            <PopupText>Список паттернов:</PopupText>
                            <PopupText>start_param - стартовый параметр</PopupText>
                            <PopupText>username - ник пользователя</PopupText>
                            <PopupText>first_name - имя пользователя</PopupText>
                            <PopupText>last_name - фамилия пользователя</PopupText>
                            <PopupText>language_code - код страны</PopupText>
                            <PopupText>tg_id - id телеграмма</PopupText>
                            <PopupText>chat_id - id чата</PopupText>
                            <br></br>
                            <PopupText>Поле Date позволяет выбрать дату, которая означает, что при рассылке сообщений будут выбраны пользователи зарегистрированные в эту дату и после нее. Если поле не выбрано - сообщения будут разосланы всем пользователям без учета даты регистрации</PopupText>
                            <CreateButton style={{width: '400px', marginTop: '20px'}} disabled={isButtonBlocked} onClick={() => setIsShowHelp(false)}>Закрыть</CreateButton>
                        </Popup>
                        }
                    </AddModal>
                </Overlay>
            }
            {editData.id !== 0 && 
            <Overlay>
                <AddModal ref={listRef}>
                    <ModalTitle>Edit bot</ModalTitle>
                    <ModalInputWrapper>
                        <EditInpurWrapper>
                            <ModalNameInput>Link:</ModalNameInput>
                            <AddInput style={{border: isEditError && !editData.data.link.trim() ? '1px solid #FF0000' : '1px solid black'}} value={editData.data.link} onChange={(e) => changeEditInputData('link', e.target.value)} placeholder="Link"></AddInput>
                        </EditInpurWrapper>
                        <EditInpurWrapper>
                            <ModalNameInput>Reply message:</ModalNameInput>
                            <AddInput style={{border: isEditError && !editData.data.reply_message.trim() ? '1px solid #FF0000' : '1px solid black'}} value={editData.data.reply_message} onChange={(e) => changeEditInputData('reply_message', e.target.value)} placeholder="Reply message"></AddInput>
                        </EditInpurWrapper>
                        <EditInpurWrapper>
                            <ModalNameInput>Support link:</ModalNameInput>
                            <AddInput style={{border: '1px solid black'}} value={editData.data.support_link} onChange={(e) => changeEditInputData('support_link', e.target.value)} placeholder="Support link"></AddInput>
                        </EditInpurWrapper>
                        <EditInpurWrapper>
                            <ModalNameInput>Subscribers count:</ModalNameInput>
                            <AddInput style={{border: '1px solid black'}} value={editData.data.subscribers} onChange={(e) => changeEditInputData('subscribers', e.target.value)} placeholder="Subscribers count"></AddInput>
                        </EditInpurWrapper>
                    </ModalInputWrapper>
                    <CreateButton disabled={isButtonBlocked} onClick={editBot}>Save</CreateButton>
                </AddModal>
            </Overlay>
            }
            {isShowButton!==0 && 
            <Overlay>
                <AddModal ref={listRef}>
                    <ModalTitle>Buttons list</ModalTitle>
                    <ButtonsList style={{height: '400px', maxHeight: '400px'}}>
                        <AddButtonsWrapper style={{height: '20px'}}>
                            <ButtonText>Name</ButtonText>
                            <ButtonText>Link</ButtonText>
                        </AddButtonsWrapper>
                        {buttonData.map((item, index) => {
                            return(
                                <AddButtonsWrapper>
                                    <ButtonText>{item.name}</ButtonText>
                                    <ButtonText>{item.link}</ButtonText>
                                    <DeleteButton disabled={isButtonBlocked} onClick={() => sendDeleteButton(index)}>Delete</DeleteButton>
                                </AddButtonsWrapper>
                            )
                        })}
                        <AddButtonsWrapper style={{border: 'none'}}>
                            <ModalAddInput style={{border: isButtonError && !name.trim() ? '1px solid #FF0000' : '1px solid black'}} value={name} onChange={(e) => setName(e.target.value)} placeholder="Name"/>
                            <ModalAddInput style={{border: isButtonError && isLinkError ? '1px solid #FF0000' : '1px solid black'}} value={link} onChange={(e) => setLink(e.target.value)} placeholder="Link"/>
                            <ModalAddButton disabled={isButtonBlocked} onClick={sendNewButton}>Add button</ModalAddButton>
                        </AddButtonsWrapper>
                    </ButtonsList>
                </AddModal>
            </Overlay>
            } 
            <Container>
                <ContainerHeader>
                    <HeaderButton onClick={() => {closePopup(); setIsOpenAddModal(true)}}>Add bot</HeaderButton>
                    <HeaderButton onClick={() => {getListBot(); setIsOpenSendMessage(true);}}>Send message</HeaderButton>
                    <HeaderButton onClick={getListBot}>Show bot list</HeaderButton>
                    <HeaderButton onClick={() => {setPopupStartBots(true)}} style={{backgroundColor: '#018301'}}>Start all bots</HeaderButton>
                </ContainerHeader>
                {list && 
                <ContainerList>
                    <Table data={list} isButtonBlocked={isButtonBlocked} setPopupDeleteBot={setPopupDeleteBot} setPopupStartBot={setPopupStartBot} setPopupStopBot={setPopupStopBot} setEditData={setEditData} setButtonData={setButtonData} setShowButton={setShowButton}></Table>
                </ContainerList>}
            </Container>
        </Background>
    )
}

export default Main;